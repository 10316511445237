import { MouseEvent, useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";
import ReactMarkdown from "react-markdown";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [message, setMessage] = useState(
    "下の入力欄に質問を入力して送信ボタンを押してね🙏"
  );

  const configuration = new Configuration({
    organization: "org-rfUvUmeM8Ako1ENAP5UUDc5n",
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const get = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputText === "") return;
    setIsLoading(true);
    const response = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      temperature: 0.2,
      messages: [
        {
          role: "system",
          content:
            "こちらから質問を投げかけるのでそれに対応する形で回答を行ってください。",
        },
        {
          role: "system",
          content: "Markdown方式で箇条書きなどを使いながら記述してください。",
        },
        {
          role: "system",
          content: "あなたは建設業にとても詳しい博士です。",
        },
        {
          role: "system",
          content: "博士のような口調でロールプレイしてください。",
        },
        {
          role: "assistant",
          content:
            "建設業のことなら何でもわかる建設AI博士だよ！何でも聞いてね！なにか聞きたいことはある？",
        },
        { role: "user", content: inputText },
      ],
    });
    const message =
      response.data.choices[0].message?.content || "別の質問をしてください。";
    setInputText("");
    setMessage(message);
    setIsLoading(false);
  };
  return (
    <div className="bg-gradient-to-br from-sky-400/20 to-cyan-300/20">
      <header className="fixed z-50 w-full border-b border-white bg-white/50 pt-6 backdrop-blur-sm">
        <div className="container mx-auto max-w-2xl px-6">
          <h1 className="mb-4 inline-flex select-none items-center gap-3 text-4xl font-bold text-slate-800">
            <Icon icon="twemoji:building-construction" />
            建設AI博士
          </h1>
        </div>
      </header>
      <div className="container mx-auto min-h-screen max-w-2xl p-6 pt-24">
        <div className="mb-24">
          <div className="rounded-2xl bg-white p-7 drop-shadow-sm">
            <div className="text-japanese">
              {isLoading ? (
                <div className="flex items-center justify-center gap-3">
                  <div className="h-2 w-2 animate-ping rounded-full bg-amber-500" />
                  <div className="h-2 w-2 animate-ping rounded-full bg-amber-500" />
                  <div className="h-2 w-2 animate-ping rounded-full bg-amber-500" />
                </div>
              ) : (
                <ReactMarkdown className="prose prose-slate text-justify">
                  {message}
                </ReactMarkdown>
              )}
            </div>
          </div>
          <div className="mx-3 mt-2 flex flex-col-reverse justify-between gap-2 sm:flex-row sm:gap-0">
            <a
              href="https://www.andeco.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className="select-none text-xs text-sky-500 underline"
            >
              ©2023 Andeco.inc
            </a>
            <p className="select-none text-xs text-slate-500">
              ※AIの回答は正確性に欠けたり、誤解を招いたりする場合があります。
            </p>
          </div>
        </div>
        <form className="fixed inset-x-6 bottom-6 mx-auto flex max-w-2xl gap-3 rounded-lg bg-white/70 p-3 backdrop-blur-sm">
          <input
            value={inputText}
            onChange={(event) => setInputText(event.target.value)}
            type="text"
            placeholder="有名な建築家を教えて"
            className="h-10 w-full select-none rounded-md border border-slate-200 bg-white/70 p-2 outline-offset-4 outline-amber-500 transition-all duration-200 ease-in-out"
          />
          <button
            onClick={(event) => get(event)}
            type="submit"
            className={`grid aspect-square h-10 w-10 place-items-center rounded-md text-sm font-bold text-white outline-offset-4 outline-amber-500 transition-colors duration-200 ease-in-out md:aspect-auto md:w-32 ${
              isLoading
                ? "animate-pulse cursor-wait bg-amber-300"
                : "bg-amber-500 hover:bg-amber-400"
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
            ) : (
              <p className="inline-flex items-center gap-2 text-xs">
                <span className="hidden md:block">送信</span>
                <PaperAirplaneIcon className="h-5 w-5" />
              </p>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;
